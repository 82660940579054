import React, { useState } from 'react';
import { TextField } from '@mui/material';

const MINUS_SIGN_CHAR_CODES = [45, 8211, 65123, 65293];

const NumberInput = (props) => {
    const [value, setValue] = useState(props.defaultValue ? Math.abs(Number(props.defaultValue)) : '');
    const typingTimeoutRef = React.useRef(null);
    const [sign, setSign] = useState(Number(props.defaultValue) < 0 ? '-' : '');

    const isValidNumber = (v) => {
        if (v === '') return true;
        if (v === '.') return false;
        if (v?.length > 1 && v[0] === '0' && v[1] !== '.') return false;
        if (v?.length === 1 && (v[0] === '.' || MINUS_SIGN_CHAR_CODES.includes(v.charCodeAt(0)))) return false;
        return /^-?\d*$/.test(v) || /^-?\d*\.\d+$/.test(v);
    };

    const onChange = (event) => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        const wasPaste = event.nativeEvent.inputType === 'insertFromPaste';
        if (wasPaste) {
            setSign(MINUS_SIGN_CHAR_CODES.includes(event.target.value.charCodeAt(0)) ? '-' : '');
        }

        const isNegative = MINUS_SIGN_CHAR_CODES.includes(event.target.value.charCodeAt(0));
        let v = event.target.value.replace(/[^0-9.]/g, '');

        const regex = /^[0-9\b]*\.?[0-9\b]*$/;
        if (event.target.value === '' || regex.test(v)) {
            setValue(v);

            let newSign = isNegative ? '-' : v === '' ? '' : sign;
            setSign(newSign);

            if (props.onChange) {
                typingTimeoutRef.current = setTimeout(() => {
                    props.onChange(newSign + v);
                }, 300);
            }
        }
    };

    const newProps = { ...props };
    delete newProps.defaultValue;

    return (
        <TextField
            {...newProps}
            onKeyDown={(event) => {
                if (event.code === 'Minus' || event.code === 'NumpadSubtract' || MINUS_SIGN_CHAR_CODES.includes(event.key.charCodeAt(0))) {
                    setSign((old) => {
                        let newSign = old === '' ? '-' : '';
                        props.onChange && props.onChange(newSign + value);
                        return newSign;
                    });
                    event.stopPropagation();
                }
            }}
            error={value != null && !isValidNumber(sign + value)}
            placeholder={props.placeholder || '0'}
            value={sign + value}
            onChange={onChange}
        />
    );
};

export default NumberInput;
