import { createElement, ReactElement } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

export interface NavDataItem {
    id: number;
    text: string;
    link: string;
    permission?: string | string[];
    icon: ReactElement;
}

export const navData: NavDataItem[] = [
    {
        id: 0,
        text: 'Home',
        link: '/',
        icon: createElement(HomeOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 1,
        text: 'Dashboards',
        link: '/dashboards',
        icon: createElement(EqualizerOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 2,
        text: 'Reports',
        link: '/reports',
        icon: createElement(SsidChartOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 3,
        text: 'Subscriptions',
        link: '/subscriptions',
        icon: createElement(EmailOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 4,
        text: 'Configuration',
        link: '/configuration',
        permission: 'View Configuration',
        icon: createElement(StorageOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 5,
        text: 'Budgets',
        link: '/budgets',
        permission: 'Edit Budgets',
        icon: createElement(CalendarTodayOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 6,
        text: 'Admin',
        link: '/admin',
        permission: ['View Users', 'View Admin Subscriptions', 'View Admin Reports/Dashboards', 'View Communities'],
        icon: createElement(SettingsOutlinedIcon, { fontSize: 'small' }),
    },
    {
        id: 7,
        text: 'SLAdmin',
        link: '/sladmin',
        permission: 'View SLAdmin',
        icon: createElement(TuneOutlinedIcon, { fontSize: 'small' }),
    },
];
