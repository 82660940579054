import axios from 'axios';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../ApplicationContext';

type ReportNameProps = {
    reportId: string;
};

const ReportName = ({ reportId }: ReportNameProps) => {
    const [reportName, setReportName] = useState('');
    const { selectedCompanyId } = useApplicationContext();

    useEffect(() => {
        const url = `/Reports/${reportId}/GetName`;
        axios
            .get(url, {
                params: { companyId: selectedCompanyId },
            })
            .then((response) => {
                setReportName(response.data);
            });
    }, [reportId, selectedCompanyId]);

    return <>{reportName}</>;
};

export default ReportName;
