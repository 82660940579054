import { NavLink } from 'react-router-dom';
import { useApplicationContext } from '../ApplicationContext';
import { navData } from '../lib/navData';
import logo from '../seniorlytics-logo.png';
import Grid from '@mui/material/Unstable_Grid2';
import './SideMenu.css';

export default function SideMenu() {
    const { toggleNav, hasPermission } = useApplicationContext();

    return (
        <>
            <Grid className={'side-bar__logo'} flexGrow={1}>
                <img src={logo} alt="logo" />
            </Grid>
            <Grid component={'nav'} className="side-menu" flexGrow={1}>
                <ul>
                    {navData
                        .filter((item) => hasPermission(item.permission))
                        .map((item) => {
                            return (
                                <li key={item.id}>
                                    <NavLink to={item.link} onClick={toggleNav} className={({ isActive }) => (isActive ? 'active' : '')}>
                                        <Grid container alignItems={'center'}>
                                            <Grid container sx={{ marginRight: '5px' }}>
                                                {item.icon}
                                            </Grid>
                                            <Grid container>{item.text}</Grid>
                                        </Grid>
                                    </NavLink>
                                </li>
                            );
                        })}
                </ul>
            </Grid>
        </>
    );
}
