import axios from 'axios';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../ApplicationContext';

type CategorizationNameProps = {
    categorizationId: string;
};

const CategorizationName = ({ categorizationId }: CategorizationNameProps) => {
    const { selectedCompanyId } = useApplicationContext();
    const [categorizationName, setCategorizationName] = useState('');

    useEffect(() => {
        const url = `/Categorization/${categorizationId}/Name/`;
        axios.get(url).then((response) => {
            setCategorizationName(response.data);
        });
    }, [categorizationId, selectedCompanyId]);

    return <>{categorizationName}</>;
};

export default CategorizationName;
