import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import StackedTable from '../StackedTable';
import { useMediaQuery } from '@react-hook/media-query';

const SLTable = (props) => {
    const isMobile = useMediaQuery('only screen and (max-width:768px)');
    let styles = {
        display: 'grid',
        width: '100%',
        borderWidth: '1px',
        borderRadius: '5px',
        padding: 2,
        marginTop: '1rem',
        '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-virtualScrollerContent--overflowed': {
            height: 'auto !important',
        },
        '& .MuiDataGrid-toolbarContainer': {},
        '& .MuiDataGrid-columnHeaders': {
            marginTop: '1rem',
            backgroundColor: '#f5f5f5',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {},
        '& .MuiDataGrid-columnHeader': {
            padding: '0 1rem',
        },
        '& .MuiDataGrid-cell': {
            padding: '0 1rem',
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        ...props.sx,
    };

    if (props.rowSelection === false) {
        styles['& .MuiDataGrid-cell:focus'] = {
            outline: 'none',
        };
        styles['& .MuiDataGrid-row:hover'] = {
            background: 'none',
        };
    }

    if (isMobile && props.stackOnMobile) {
        return <StackedTable columns={props.columns} rows={props.rows} loading={props.loading} onRowClick={props.onRowClick} />;
    } else {
        return <DataGridPro {...props} sx={styles} />;
    }
};

export default SLTable;
