import { Box, Button, Divider, LinearProgress, Popover, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export enum FileTypes {
    XLS = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV = 'text/csv',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}

export const FILE_TYPE_LABELS = {
    [FileTypes.XLS]: 'XLS',
    [FileTypes.XLSX]: 'XLSX',
    [FileTypes.CSV]: 'CSV',
    [FileTypes.PNG]: 'PNG',
    [FileTypes.JPEG]: 'JPEG',
};

const MAX_FILE_COUNT = 10;

export const isImageType = (type: string) => {
    return type === FileTypes.PNG || type === FileTypes.JPEG;
};

interface FileUploadProps {
    defaultURL?: string | string[];
    onChange: (file: File[]) => void;
    fileTypes?: string[];
    fileTypesLabel?: string;
    allowMultiple?: boolean;
    dropzoneLabel?: string;
    DropzoneIcon?: React.FC;
    required?: boolean;
    sx?: any;
}

const FileUpload = ({
    defaultURL,
    onChange,
    fileTypes,
    fileTypesLabel,
    allowMultiple = false,
    DropzoneIcon,
    required = false,
    sx,
}: FileUploadProps) => {
    const theme = useTheme();
    const [value, setValue] = useState<File[] | null>([]);
    const [fileURLs, setFileURLs] = useState<string[]>(defaultURL ? (Array.isArray(defaultURL) ? defaultURL : [defaultURL]) : []);
    const [uploadProgress, setUploadProgress] = useState<object>({});
    const totalProgress = useMemo(
        () => (Object.values(uploadProgress).reduce((a, b) => a + b, 0) / (100 * Object.values(uploadProgress).length)) * 100,
        [uploadProgress],
    );
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [hovering, setHovering] = useState(false);
    const isImage = fileTypes && fileTypes.every(isImageType);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const readFile = useCallback((blob, key) => {
        const reader = new FileReader();
        reader.addEventListener('progress', (e) => {
            if (e.lengthComputable && e.total && e.loaded) {
                setUploadProgress((old) => ({
                    ...old,
                    [key]: (e.loaded / e.total) * 100,
                }));
            }
        });
        reader.addEventListener('error', (e) => {
            setUploadError('An error occurred while reading the file');
        });
        reader.addEventListener('loadend', (e) => {
            if (e.target.result) {
                setFileURLs((old) => [...old, e.target.result as string]);
            }
        });

        reader.readAsDataURL(blob);
    }, []);

    const enterDropZone = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setHovering(true);
    };

    const leaveDropZone = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setHovering(false);
    };

    const dropFiles = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        onFilesChange(files);
    };

    const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.target.files;
        onFilesChange(files);
    };

    const onFilesChange = useCallback(
        (fileList: any) => {
            if (!allowMultiple && fileList.length > 1) {
                setUploadError('Multiple files are not allowed');
            } else if (fileList && fileList.length > 0) {
                if (fileList.length > MAX_FILE_COUNT) {
                    setUploadError(`You can only upload ${MAX_FILE_COUNT} files at a time`);
                    return;
                }

                for (let i = 0; i < fileList.length; i++) {
                    if (fileTypes && !fileTypes.includes(fileList[i].type)) {
                        setUploadError('Invalid file type');
                        return;
                    }
                }

                setUploadError(null);
                setUploadProgress({});
                setFileURLs([]);

                if (allowMultiple) {
                    let files = [];

                    for (let i = 0; i < fileList.length; i++) {
                        files.push(fileList[i]);
                        readFile(fileList[i], fileList[i].name);
                        const reader = new FileReader();
                        reader.readAsDataURL(fileList[i]);
                    }
                    setValue(files);
                    onChange(files);
                } else {
                    setValue([fileList[0]]);
                    onChange([fileList[0]]);
                    readFile(fileList[0], fileList[0].name);
                }
            }

            setHovering(false);
        },
        [readFile, allowMultiple, onChange, fileTypes],
    );

    const clearFiles = () => {
        setValue([]);
        setFileURLs([]);
        onChange([]);
        setUploadProgress({});
        setUploadError(null);
    };

    return (
        <Stack
            spacing={2}
            onDragOver={enterDropZone}
            onDragLeave={leaveDropZone}
            onDrop={dropFiles}
            sx={{
                border: `2px ${hovering ? 'solid' : 'dashed'}`,
                borderColor: hovering ? theme.palette.success.main : '#ccc',
                borderRadius: '5px',
                backgroundColor: hovering ? theme.palette.success.light : 'none',
                padding: '20px',
                textAlign: 'center',
                color: hovering ? theme.palette.success.main : 'grey',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
        >
            {fileURLs[0] && isImage ? (
                // TODO: carousel
                // @ts-ignore
                <img src={fileURLs[0]} alt={'Uploaded file'} style={{ width: '100%', maxHeight: '80%', borderRadius: '5px', objectFit: 'cover' }} />
            ) : (
                <Stack justifyContent={'center'} alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                    {value.length <= 0 && (
                        <>
                            {DropzoneIcon ? <DropzoneIcon /> : <FileUploadOutlinedIcon fontSize={'large'} />}
                            <Stack alignItems={'center'}>
                                {hovering ? 'Drop file' : `Drag and drop file ${required ? '*' : ''}`}

                                {fileTypesLabel ? (
                                    <Typography variant={'caption'}>{fileTypesLabel}</Typography>
                                ) : (
                                    <Typography variant={'caption'}>({fileTypes.map((ft) => FILE_TYPE_LABELS[ft]).join(', ')})</Typography>
                                )}
                            </Stack>
                        </>
                    )}
                    {uploadError && (
                        <Typography variant={'subtitle2'} color={'error'}>
                            {uploadError}
                        </Typography>
                    )}
                    {value.length > 0 && (
                        <Stack>
                            {Object.values(value)
                                .slice(0, 3)
                                .map((f) => (
                                    <Typography key={f.name} variant={'subtitle2'}>
                                        {f.name}
                                    </Typography>
                                ))}
                            {value.length > 3 && (
                                <Box>
                                    <Typography
                                        aria-owns={open ? 'file-upload-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                    >
                                        + {value.length - 3} more
                                    </Typography>
                                    <Popover
                                        id="file-upload-popover"
                                        sx={{ pointerEvents: 'none' }}
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <Box sx={{ pb: 1 }}>
                                            {Object.values(value)
                                                .slice(3)
                                                .map((f) => (
                                                    <Typography sx={{ px: 2, pt: 1 }}>{f.name}</Typography>
                                                ))}
                                        </Box>
                                    </Popover>
                                </Box>
                            )}
                        </Stack>
                    )}
                    {totalProgress > 0 && (
                        <Stack sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ minWidth: 35, mt: 1 }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(totalProgress)}%`}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', mt: 1 }}>
                                <LinearProgress sx={{ borderRadius: 5 }} variant="determinate" value={totalProgress} />
                            </Box>
                        </Stack>
                    )}
                </Stack>
            )}
            <Stack alignItems={'center'} sx={{ width: '100%' }} spacing={2}>
                {fileURLs[0] == null && <Divider sx={{ width: '30%' }}>or</Divider>}
                <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                    <Button component="label" size={'small'} startIcon={<FileUploadOutlinedIcon />} variant={'outlined'} color={'primary'}>
                        <input type="file" multiple={allowMultiple} maxLength={10} hidden onChange={uploadFiles} />
                        Upload
                    </Button>
                    {fileURLs[0] && (
                        <Button
                            onClick={clearFiles}
                            component="label"
                            size={'small'}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            variant={'outlined'}
                            color={'error'}
                        >
                            Clear
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FileUpload;
