import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import UserEditSubscriptionModal from './UserEditSubscriptionModal';
import { EditSubscriptionButtonProps } from './subscriptionTypes';
import { useApplicationContext } from '../../ApplicationContext';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

const EditSubscriptionButton = ({ ButtonComponent, reportId, isDashboard, refreshCallback = () => {} }: EditSubscriptionButtonProps) => {
    const { selectedCompanyId } = useApplicationContext();

    const [showDialog, setShowDialog] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [report, setReport] = useState({});

    const getCurrentSubscription = useCallback(() => {
        return axios
            .get(`/Subscriptions/${reportId}/GetUserSubscription`, {
                params: { companyId: selectedCompanyId },
            })
            .then((subscription) => {
                setCurrentSubscription(subscription.data);
            });
    }, [reportId, selectedCompanyId]);

    useEffect(() => {
        setIsLoading(true);
        if (reportId) {
            Promise.all([
                axios
                    .get(`/Subscriptions/${reportId}/IsSubscribed`, {
                        params: { companyId: selectedCompanyId },
                    })
                    .then((response) => {
                        setIsSubscribed(response.data);
                        if (response.data) {
                            return getCurrentSubscription();
                        } else {
                            setCurrentSubscription(null);
                        }
                    })
                    .catch(console.log),
                axios
                    .get(`/Reports/${reportId}`, {
                        params: { companyId: selectedCompanyId },
                    })
                    .then((response) => {
                        setReport(response.data);
                    }),
            ]).finally(() => setIsLoading(false));
        }
    }, [getCurrentSubscription, reportId, selectedCompanyId]);

    return (
        <div>
            {ButtonComponent ? (
                <ButtonComponent disabled={isLoading} isSubscribed={isSubscribed} onClick={() => setShowDialog(true)} />
            ) : (
                <Button
                    disabled={isLoading}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    startIcon={<EmailRoundedIcon />}
                    onClick={() => setShowDialog(true)}
                    style={{ marginRight: '10px' }}
                >
                    {!isSubscribed ? 'Subscribe' : 'Edit Subscription'}
                </Button>
            )}
            <UserEditSubscriptionModal
                reportId={reportId}
                report={report}
                isDashboard={isDashboard}
                currentSubscription={currentSubscription}
                getCurrentSubscription={getCurrentSubscription}
                setIsSubscribed={setIsSubscribed}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                refreshCallback={refreshCallback}
            />
        </div>
    );
};

export default EditSubscriptionButton;
