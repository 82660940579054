import { useApplicationContext } from '../../ApplicationContext';
import { useEffect, useState } from 'react';
import axios from 'axios';

type GLCodeSetNameProps = {
    glCodeSetId: string;
};
const GLCodeSetName = ({ glCodeSetId }: GLCodeSetNameProps) => {
    const { selectedCompanyId } = useApplicationContext();
    const [codeSetName, setCodeSetName] = useState('');

    useEffect(() => {
        (async () => {
            const url = `/GLCodeSet/${glCodeSetId}/Name/`;
            axios.get(url).then((response) => {
                setCodeSetName(response.data);
            });
        })();
    }, [glCodeSetId, selectedCompanyId]);

    return <>{codeSetName}</>;
};

export default GLCodeSetName;
