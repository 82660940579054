import Grid from '@mui/material/Unstable_Grid2';
import { InputAdornment, LinearProgress, TextField } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useCallback, useMemo, useState } from 'react';

const StackedTable = ({ columns, rows, loading, onRowClick }) => {
    const [searchText, setSearchText] = useState('');

    const getRowValue = useCallback((row, column) => {
        if (column.renderCell) {
            return column.renderCell({ row });
        } else if (column.valueGetter) {
            return column.valueGetter({ row });
        } else {
            return row[column.field];
        }
    }, []);

    const filteredRows = useMemo(
        () => [
            ...rows.filter((row) => {
                let fields = columns.filter((c) => c.filterable || c.filterable === undefined);
                return fields.some((field) => {
                    return String(getRowValue(row, field)).toLowerCase().includes(searchText.toLowerCase());
                });
            }),
        ],
        [searchText, rows, columns, getRowValue],
    );

    return (
        <Grid container direction={'column'} className={'stacked-table'}>
            <Grid container className={'stacked-table__header'} justifyContent={'end'} flexGrow={1}>
                <TextField
                    variant={'standard'}
                    size={'small'}
                    placeholder={'Search'}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRoundedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            {loading && (
                <Grid>
                    <LinearProgress color={'primary'} sx={{ height: '4px' }} />
                </Grid>
            )}
            <Grid className={'stacked-table__body'}>
                {filteredRows.map((row, rowIndex) => {
                    return (
                        <Grid className={'stacked-table__row'} key={rowIndex} container direction={'column'} onClick={() => onRowClick?.({ row })}>
                            {columns.map((column, columnIndex) => {
                                return (
                                    <Grid
                                        className={'stacked-table__cell'}
                                        key={columnIndex}
                                        container
                                        flexWrap={'nowrap'}
                                        justifyContent={'space-between'}
                                    >
                                        <Grid className={'stacked-table__cell--label'}>{column.headerName}</Grid>
                                        <Grid className={'stacked-table__cell--value'}>{getRowValue(row, column)}</Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default StackedTable;
