import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { browserName, isMobile, osName } from 'react-device-detect';
import { useApplicationContext } from '../ApplicationContext';
import { Outlet } from 'react-router-dom';

const AnalyticsContext = React.createContext(null);

export enum EventTypes {
    REPORT_VIEW = 'Report View',
}

export interface Event {
    type: EventTypes;
    value: any;
}

interface SLDimensions {
    userId?: string;
    companyId?: number;
    location?: string;
    browserName?: string;
    osName?: string;
    osVersion?: string;
    isMobile?: boolean;
}

const cache = {};

const useAnalyticsContext = () => {
    const context = React.useContext(AnalyticsContext);

    if (context === undefined) {
        throw new Error('useAnalyticsContext must be used within an AnalyticsContext.Provider');
    }
    return context;
};

const Provider = () => {
    const { currentUser, selectedCompanyId, location } = useApplicationContext();
    const [slDimensions, setSlDimensions] = useState<SLDimensions>();

    useEffect(() => {
        if (!currentUser || !selectedCompanyId) return;

        setSlDimensions({
            ...location,
            userId: currentUser?.id,
            companyId: selectedCompanyId,
            browserName,
            osName,
            isMobile,
        });
    }, [currentUser, selectedCompanyId, location]);

    const event = useCallback(
        async ({ type = EventTypes.REPORT_VIEW, value }: Event) => {
            if (!value || !slDimensions) return;

            const now = new Date().getTime();

            const data: any = {
                type,
                value,
                ...slDimensions,
            };

            const then = cache[JSON.stringify(data)];
            if (then && now - then < 2500) return;
            cache[JSON.stringify(data)] = now;

            try {
                await axios.post(`/Users/Analytics/Event`, data, {
                    headers: {
                        'Content-Type': 'text/json',
                    },
                });
            } catch (error) {
                console.error(error);
            }
        },
        [slDimensions],
    );

    return (
        <AnalyticsContext.Provider
            value={{
                event,
            }}
        >
            <Outlet />
        </AnalyticsContext.Provider>
    );
};

const AnalyticsProvider = Provider;

export { AnalyticsContext, AnalyticsProvider, useAnalyticsContext };
