import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Unstable_Grid2';

export default function SLTableToolbar({ ...props }) {
    return (
        <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
            <Grid container spacing={2}>
                {props.saveButton && <Grid>{props.saveButton}</Grid>}
                {props.createButton && <Grid>{props.createButton}</Grid>}
                {props.uploadButton && <Grid>{props.uploadButton}</Grid>}
                {props.createModal && <Grid>{props.createModal}</Grid>}
            </Grid>
            <GridToolbarQuickFilter
                debounceMs={props.debounceMs}
                quickFilterParser={(searchInput) => searchInput.split(',').map((value) => value.trim())}
                quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(',')}
            />
        </GridToolbarContainer>
    );
}
